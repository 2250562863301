<template>
  <div class="app-contianer">
    <div class="page-bg">
      <div class="bg"></div>
    </div>
    <div id="app-content">
      <div class="page-item account-box mainBgColor">
        <div v-if="defaultDoor" class="info">
          <div class="f38">{{defaultDoor.acctFlag | acctFlagFilter}} / {{defaultDoor.city}} / {{defaultDoor.idcard}}
          </div>
          <div class="f40 margin-tb2"><strong>{{defaultDoor.acctNo}}</strong></div>
        </div>
        <div v-else class="margin-tb3">请绑定充值户号</div>
        <div class="f40" @click="goPage('/doorList')">切换户号<span class="page-icon arrows-right"></span></div>
      </div>

      <div class="radio-box">
        <div class="title f40"><strong>充值金额</strong></div>
        <div class="content">
          <span class="item" :class="product.id === item.id ? 'mainColor mainBorder':''" v-for="item in productList"
            :key="item.id" @click="changePorduct(item)">
            <span class="dec mainBgColor">立减 {{item.faceValue - item.cost}} 元</span>
            <div class="f45">{{item.faceValue}}元</div>
            <div class="f34">折扣价: {{item.cost}}元</div>
          </span>
        </div>
      </div>

      <div class="payType">
        <div class="title f40"><strong>支付方式</strong></div>
        <div v-if="versions === 'WeChat' && wxPay === 'Y'" class="item" @click="payTrxCode = 'TC003'">
          <img src="../../../assets/user/wxzf.png" />
          <span class="text">微信</span>
          <van-icon :class="payTrxCode === 'TC003' ? 'mainBgColor mainBorder':''" name="success" />
        </div>
        <div v-if="zfbPay === 'Y'" class="item" @click="payTrxCode = 'TC011'">
          <img src="../../../assets/user/zfb.png" />
          <span class="text">支付宝</span>
          <van-icon :class="payTrxCode === 'TC011' ? 'mainBgColor mainBorder':''" name="success" />
        </div>
      </div>

      <div class="tip-box ">
        <div class="title f40"><strong>温馨提醒</strong></div>
        <div class="content f38">
          <p>【充值对象】<span class="red">支持南方电网/网上国网缴费可开发票。</span></p>
          <p>【到账时间】24-72小时内到账，单笔订单可能存在拆分到账情况<span class="red">（50/100/200等）</span>，到账后可在“网上国网APP”绑定对应信息查询到账情况</p>
          <p>【国网充值】单一户号<span class="red">每天充值上限为1000元</span> ，超出可能会导致延迟到账，<span class="red">单笔缴费金额需大于欠费金额才可充值!</span>
          </p>
          <p>【南网充值】单一户号<span class="red">每天充值上限为1000元</span> ，超出可能会导致延迟到账，<span class="red">单笔缴费金额需大于欠费金额才可充值!</span>
          </p>
          <!-- <p>【充值成功】充值成功将会收到公众号充值成功提示，可 到当地电业局开具对应额度的发票。</p> -->
          <p>【充值失败】如充值失败，会自动退回到对应账户，请注意查收；如又疑问，可以联系平台客服进行咨询。</p>
          <p>【充值必看】<span class="red">充值前请认真核对电户号等信息</span>，一经充值成功，概不退款。</p>
        </div>

      </div>

      <div class="footer-box">
        <div class="icon" @click="goPage('/contactus')"><span class="page-icon contact"></span></div>
        <div class="icon" @click="goPage('/electricityRecord')"><span class="page-icon record"></span></div>
        <div class="rechargeBut f40 mainBgColor" @click="submit()">立即充值</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mallApi } from '@/api'
import { mapState, mapActions } from 'vuex'
import theAlipay from '@/components/Alipay.js'
import { Toast } from 'vant'
import wx from 'weixin-js-sdk'

const acctFlagMap = Object.freeze([
  { name: '国家电网', value: 'SGCC' },
  { name: '南方电网', value: 'CSG' }]
)

export default {
  name: 'electricity',
  data() {
    return {
      productList: [],
      product: {},
      payTrxCode: '',
      loading: false,
      wxPay: 'N',
      zfbPay: 'N'
    }
  },
  computed: {
    ...mapState('electricity', ['defaultDoor']),
    ...mapState('user', ['versions']),
  },
  filters: {
    acctFlagFilter(val) {
      let item = acctFlagMap.find(v => v.value === val)
      return item ? item.name : val
    }
  },
  async created() {
    await this.getDoorList()
    this.getElectricityInfo()
  },
  methods: {
    ...mapActions('electricity', ['getDoorList']),

    // 获取电费价格信息
    getElectricityInfo() {
      console.log(this.defaultDoor);
      let productType = 'ELECTRIC_SGCC'
      if (this.defaultDoor && this.defaultDoor.acctFlag) {
        productType = `ELECTRIC_${this.defaultDoor.acctFlag}`
      }
      let params = {
        trxCode: 'XF330',
        productType: productType
      }
      mallApi(params).then(res => {
        const { rspCode, rspMsg, data } = res
        if (rspCode === '0000') {
          this.productList = data.productList
          this.product = data.productList[0]
          this.wxPay = data.WECHATPAY
          this.zfbPay = data.ALIPAY
        } else {
          this.$toast(rspMsg)
        }

      })
    },


    // 选择产品
    changePorduct(item) {
      this.product = item
    },

    // 充值
    submit() {
      if (this.loading) {
        return
      }
      if (!this.defaultDoor) {
        this.$toast('请先选择户号')
        return
      }

      if (this.wxPay === 'N' && this.zfbPay === 'N') {
        this.$toast('无可用支付渠道, 请联系服务商~')
        return;
      }

      if (!this.payTrxCode) {
        this.$toast('请选择支付方式')
        return;
      }

      this.loading = true
      let toast = Toast.loading({
        duration: 0, // 持续展示
        forbidClick: true,
        message: '受理中...',
      })
      let params = {
        trxCode: 'XF351',
        productId: this.product.id,
        custAcctId: this.defaultDoor.id,
        payTrxCode: this.payTrxCode
      }

      mallApi(params).then(res => {
        const { data, rspCode, rspMsg } = res
        this.loading = false
        toast.clear()

        if (rspCode === 'P000') {
          if (params.payTrxCode === 'TC011') {
            theAlipay({ url: data.codeUrl })
          }
          if (params.payTrxCode === 'TC003') {
            var codeUrl = data.payInfo
            wx.chooseWXPay({
              timestamp: codeUrl.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: codeUrl.nonceStr, // 支付签名随机串，不长于 32 位
              package: codeUrl.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: codeUrl.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
              paySign: codeUrl.paySign, // 支付签名
              success: function (res) {
                this.$toast.success('支付成功')
              },
              fail(err) {
                this.$toast.fail(err.errMsg)
              },
            })
          }
        } else {
          this.$toast(rspMsg)
        }
      })
    },

    // 页面跳转
    goPage(path) {
      this.$router.push({ path: path })
    },

  }
}
</script>

<style lang='less' scoped>
.account-box {
  padding: 24px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;

  .page-icon.arrows-right {
    width: 9px;
    height: 14px;
    background-position: -77px -122px;
    margin-left: 8px;
  }
}
.radio-box {
  margin: 12px;
  margin-top: 24px;

  .title {
    margin-bottom: 18px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .item {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 24px;
    padding: 16px 0;
    border-radius: 8px;
    width: 48%;
    background: #fff;
    font-size: 3.8vw;
    color: #777;
    border: 1px solid #fff;
  }
  .item.hide {
    background: transparent;
  }
  .dec {
    position: absolute;
    top: -8px;
    left: -1px;
    font-size: 3vw;
    color: #fff;
    padding: 0 8px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.tip-box {
  margin: 12px;
  margin-top: 24px;

  .title {
    margin-bottom: 12px;
  }
  .content {
    color: #666;
  }
  p {
    margin-bottom: 6px;
  }
}
.footer-box {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;

  .icon {
    width: 46px;
    height: 46px;
    background: #fff;
    margin-right: 12px;
    text-align: center;
    line-height: 46px;
    border-radius: 10px;
  }
  .page-icon {
    width: 22px;
    height: 22px;
    vertical-align: middle;
  }
  .page-icon.contact {
    background-position: -132px -86px;
  }
  .page-icon.record {
    background-position: -167px -86px;
  }
  .rechargeBut {
    flex: 1;
    text-align: center;
    line-height: 46px;
    color: #fff;
    border-radius: 10px;
  }
}
.payType {
  margin: 4vw;
  font-size: 4vw;

  > .title {
    padding-bottom: 8px;
  }

  .item {
    display: flex;
    align-items: center;
    padding: 6px 0;

    img {
      width: 7vw;
      margin-right: 2vw;
    }
    .text {
      flex: 1;
    }
    .van-icon-success {
      padding: 0.5vw;
      color: #fff;
      border: 1px solid #999;
      border-radius: 50%;
    }
  }
  .item.active {
    .van-icon-success {
      background: #9e7bff;
      border-color: #9e7bff;
    }
  }

  .zfb-info {
    font-size: 3.8vw;
    margin-top: 2vw;
  }
}
</style>